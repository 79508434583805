"use client";
import React, { useEffect, useState } from "react";
import Link from "next/link";
import Image from "next/image";
import "./Headers.css";

export default function Headers() {
  const [subMenuIsOpen, setsubMenuIsOpen] = useState(false);
  const [openMenu, setopenMenu] = useState(false);
  const [isTransparent, setIsTransparent] = useState(true);

  const toggleSubMenu = () => {
    setsubMenuIsOpen(!subMenuIsOpen);
  };

  const toggleMenu = () => {
    setopenMenu(!openMenu);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsTransparent(window.scrollY < 100); // Cambia trasparenza dopo 50px di scroll
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div
      className={`${
        isTransparent
          ? "bg-foreground animate__animated "
          : "animate__animated animate__fadeOutUp"
      } header`}
    >
      <div className="flex flex-row gap-5 justify-around items-center w-full">
        <div className="animate__animated animate__slideInDown animate__slow">
          <Link href="/">
            <Image src="https://paolomarino.b-cdn.net/logo.webp" alt="Next.js" width={200} height={40} />
          </Link>
        </div>
        <div className="toggle-menu" onClick={() => toggleMenu()}>
          <Image
            src="/hamburger.webp"
            alt="menu"
            width={40}
            height={40}
            className={openMenu ? "transition-all opacity-50" : ""}
          />
        </div>
        <div className="nav flex flex-row animate__animated animate__slideInDown animate__slow">
          <Link href="/">
            <div className="menu-item">Home</div>
          </Link>

          <Link href="/blog">
            <div className="menu-item">Blog</div>
          </Link>

          <Link onClick={() => setopenMenu(false)} href="/portfolio">
            <div className="menu-item">Portfolio</div>
          </Link>

          <div
            className="menu-item flex flex-col items-center relative"
            onClick={() => toggleSubMenu()}
            onMouseEnter={() => setsubMenuIsOpen(true)}
            onMouseLeave={() => setsubMenuIsOpen(false)}
          >
            Download
            <div
              className={`submenu absolute mt-10 bg-foreground transition-all duration-200 rounded-xl ${
                subMenuIsOpen ? "block" : "hidden"
              }`}
            >
              <Link href="/download">
                <div className="submenu-item">Plugin gratuiti</div>
              </Link>
              <Link href="/risorse-youtube">
                <div className="submenu-item rounded-xl">Risorse YouTube</div>
              </Link>
            </div>
          </div>
          
          {/* <Link href="/chi-sono">
            <div className="menu-item">Chi sono?</div>
          </Link> */}
          {/* <div className="flex flex-row gap-5 items-center">
            <Link href="https://telegram.me/techtracknow">
              <img src="/ttn.webp" alt="TTN" width={200} height={29} />
            </Link>
          </div> */}
        </div>
      </div>

      {openMenu && (
        <div className="nav mobile flex flex-row animate__animated animate__slideInRight">
          <Link onClick={() => setopenMenu(false)} href="/">
            <div className="menu-item">Home</div>
          </Link>

          <Link onClick={() => setopenMenu(false)} href="/blog">
            <div className="menu-item">Blog</div>
          </Link>
          <Link onClick={() => setopenMenu(false)} href="/portfolio">
            <div className="menu-item">Portfolio</div>
          </Link>
          <div
            className="menu-item flex flex-col items-center relative"
            onClick={() => toggleSubMenu()}
          >
            Download
            <div
              className={`submenu absolute mt-10 bg-foreground transition-all duration-200 rounded-xl ${
                subMenuIsOpen ? "block" : "hidden"
              }`}
            >
              <Link onClick={() => setopenMenu(false)} href="/download">
                <div className="submenu-item">Plugin gratuiti</div>
              </Link>
              <Link onClick={() => setopenMenu(false)} href="/risorse-youtube">
                <div className="submenu-item rounded-xl">Risorse YouTube</div>
              </Link>
            </div>
          </div>
          
          {/* <Link onClick={() => setopenMenu(false)} href="/chi-sono">
            <div className="menu-item">Chi sono?</div>
          </Link> */}
          {/* <div className="flex flex-row gap-5 items-center">
            <Link href="https://telegram.me/techtracknow">
              <img src="/ttn.webp" alt="TTN" width={200} height={29} />
            </Link>
          </div> */}
        </div>
      )}
    </div>
  );
}
